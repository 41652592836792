export const FRONTEND_ENDPOINTS = {
  login: `/login`,
  dashboard: `/dashboard`,
  order: `/dashboard/new-order-form`,
  customAirtimeRecharge: `/dashboard/custom-airtime-recharge`,
  createUser: `/dashboard/create-user`,
  companyInfo: `/dashboard/company-information`,
  createCompany: `/dashboard/create-company`,
  vouchers: '/dashboard/vouchers',
  available: '/dashboard/available',
  redeemed: '/dashboard/redeemed'
};
