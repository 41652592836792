export class ElementState {
  loading = false;
  loaded = false;
  success = false;
  private _errorMessage = '';
  private _successMessage = '';
  private _errorTrace = [];
  /**
   * Creates an instance of ElementState. This model is used to handle
   * the state of elements on each component.
   * @param {boolean} [startSubmitting] Whether or not the component is
   * submitting on creation (this is often true when you're downloading
   * information immediately)
   * @memberof ElementState
   */
  constructor(startSubmitting?: boolean) {
    this.reset();
    if (startSubmitting) {
      this.submit();
    }
  }

  /**
   * Call this function when the element should be loading (is submitting).
   *
   * @memberof ElementState
   */
  submit(): void {
    this.loading = true;
    this.loaded = false;
    this.success = false;
  }

  /**
   * The element has completed successfully.
   *
   * @param {*} [success] The success object, we try to pull out the message
   * but if it doesn't exist then it just stringifies the object.
   * @memberof ElementState
   */
  completedSuccess(success: any = {}): void {
    this.complete(true);
    if (success) {
      this.setSuccess(success);
    }
  }

  /**
   * The submission failed, we set the error message here.
   *
   * @param {*} [error] The error message or object. We try to set the
   * message but if we can't then we stringify the object.
   * @memberof ElementState
   */
  completedFailed(error: any = {}): void {
    this.complete(false);
    this.setError(error);
  }

  /**
   * Sets the state to completed and whether or not it was successful.
   *
   * @param {boolean} success Whether or not the state should be set to
   * successful.
   * @memberof ElementState
   */
  complete(success: boolean): void {
    this.loaded = true;
    this.loading = false;
    this.success = success;
  }

  /**
   * Reset the state of the elemnt.
   *
   * @memberof ElementState
   */
  reset(): void {
    this.loading = false;
    this.loaded = false;
    this.success = false;
    this.clearError();
  }

  /**
   * Effectively resets the state of the element.
   *
   * @memberof ElementState
   */
  clearError(): void {
    this._errorMessage = '';
    this._errorTrace = [];
  }

  /**
   * True when the state has failed.
   *
   * @returns {boolean} Whether or not the state has been set to failed.
   * @memberof ElementState
   */
  hasFailed(): boolean {
    return this.loaded && !this.success;
  }

  /**
   * True when the state was a success.
   *
   * @returns {boolean} Whether or not the state was set to successful
   * @memberof ElementState
   */
  wasSuccessful(): boolean {
    return this.loaded && this.success;
  }

  /**
   * Returns the error message if one exists, otherwise it returns an empty
   * string.
   *
   * @returns {string}
   * @memberof ElementState
   */
  errorMessage(): string {
    return this._errorMessage || '';
  }

  /**
   * Returns the trace if one exists.
   *
   * @returns {string[]}
   * @memberof ElementState
   */
  errorTrace(): string[] {
    return this._errorTrace || [];
  }

  /**
   * Returns the success message if one exists, otherwise it returns an empty
   * string.
   *
   * @returns {string}
   * @memberof ElementState
   */
  successMessage(): string {
    return this._successMessage || '';
  }

  /**
   * Returns whether the state has changed from the initial state.
   *
   * @returns {boolean}
   * @memberof ElementState
   */
  dirty(): boolean {
    return this.loading || this.wasSuccessful() || this.hasFailed();
  }

  /**
   * The method used to extract the error message. This will grow over time
   * as integrations with other APIs grow.
   *
   * @private
   * @param {*} error The error object. This can come from anywhere.
   * @returns {void} Sets the internal error message if it can find it.
   * @memberof ElementState
   */
  private setError(error: any = {}): void {
    if (error.error) {
      error = error.error;
    }

    if (error.trace) {
      this._errorTrace = error.trace;
    }

    if (error.message && error.message !== '') {
      this._errorMessage = error.message;
      return;
    }

    if (error) {
      this._errorMessage = JSON.stringify(error);
      return;
    }
  }

  /**
   * Sets the successful state message if possible.
   *
   * @private
   * @param {*} success The object that states whether or not it was
   * successful.
   * @returns {void} Sets the internal success message if it can find a
   * success message in the object.
   * @memberof ElementState
   */
  private setSuccess(success: any = {}): void {
    if (success.message) {
      this._successMessage = success.message;
      return;
    }
    if (success) {
      this._successMessage = JSON.stringify(success);
      return;
    }
  }
}
